import React, {  useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Title from 'components/title';
import {AppContext} from '../../store/createContext';

const EventSelect = () =>  {
  const {start, 
    started, 
    error,
    result,
    currentEvent,
    setCurrentEvent,
} = useContext(AppContext);
    const [nid, setNid] = useState(currentEvent.nid);
    useEffect(() => {
      if(result){
        setCurrentEvent(result.data)
      }
  
    }, [result])

   
    

    return ( 
  <div>
      <input value={nid} onChange={e => setNid(e.target.value)} />
      <button type="button" onClick={() => start(nid)}>Fetch</button>
     {/*  {error && 'Error:'+ error.message }
      {started && 'Fetching...'}
      {result &&  
 
      <Title as="h2" size="large">
      <pre>{String(result.data.name) }</pre>
      </Title>
     */}

  </div>
    )
}

EventSelect.propTypes = {
    selectedEvent: PropTypes.func,
  }

  export default EventSelect;

  
 