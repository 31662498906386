import React from 'react';
import { dark } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { generate } from 'grommet/themes/base';
import { FormDown, Bold } from 'grommet-icons';

const cronoTheme = {
  global: {
    font: {
      family: 'Montserrat, Roboto, sans-serif',
      size: '14px',
      lineHeight: '20px',
    },
    colors: {
      brand: '#353535',
      focus: '#9B9B9B',
      [dark - 5]: '#aaaaaa',
      [dark - 6]: '#bbbbbb',
      // [light-1]: '#ededed', // has error "light not defined"
    },
    input: {
      padding: '5px;', // this renders a 4px padding!
    },
  },

  button: {
    hoverIndicator: {
      dark: { color: dark - 6 },
      light: { color: 'light-3' },
      border: { radius: '5px' },
    },
    disabled: {
      color: dark - 4,
      opacity: '0.6',
    },
    border: {
      width: '1px',
      color: 'rgb(238,238,238)',
      radius: '4px',
    },
    padding: 'none',
  },

  select: {
    background: 'dark-1',
    icons: {
      color: 'rgb(238,238,238)',
      margin: '0px 0px',
      down: <FormDown />,
    },
    control: {
      open: {
        color: 'rgb(238,238,0)',
      },
    },
    options: {
      container: {
        pad: 'xxsmall',
        background: 'dark-1',
      },
      text: {
        margin: 'none',
        size: 'small',
        color: 'light-1',
      },
    },
    container: {
      extend: () => `
        flex-grow: 1;
      `,
    },
  },

  textArea: {
    // not working: background: ${ localTheme.global.colors[dark-2] }; // dark-2
    extend: () => `
      background: ${'#333333'}; // dark-1
      margin: 2px 0px;
      height: 100px;

      &:hover {
        background: ${'#555555'}; // dark-2
      }

      &:focus {
        background: ${'#555555'}; // dark-2
        color: ${'#ffffff'};
      }

      &::placeholder {
        color: dark-5;
        font-style: italic;
        font-weight: 200;
      }
    `,
  },

  textInput: {
    extend: `
      background: ${'#333333'}; // dark-1
      margin: 2px 0px;

      &:hover {
        background: ${'#555555'}; // dark-2
      }

      &:focus {
        background: ${'#555555'}; // dark-2
        color: ${'#ffffff'};
      }

      &::placeholder {
        color: dark-5;
        font-style: italic;
        font-weight: 200;
      }
    `,
  },
  table: {
    body: {
      align: 'center',
      pad: { horizontal: 'large', vertical: 'xsmall' },
      border: 'horizontal',
    },
    extend: () => `
    font-family: Arial;
    border:1px solid #000`,
    footer: {
      align: 'start',
      border: 1,
      pad: { horizontal: 'large', vertical: 'small' },
      verticalAlign: 'bottom',
    },
    header: {
      align: 'center',
      border: 'bottom left',
      fill: 'horizontal',
      pad: { horizontal: 'large', vertical: 'xsmall' },
      verticalAlign: 'bottom',
      weight: Bold,
      background: {
        color: 'brand',
        opacity: 'strong',
      },
    },
  },
};

export default cronoTheme;
