import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, navigate } from 'gatsby';
import Head from 'components/head';
//import Header from 'components/header';
import EventSelect from 'components/cronoapp/EventSelect';
import GlobalStyle from 'global.css.js';
import { Grommet, Header, Anchor, Menu, Button, Heading } from 'grommet';
import { cronoTheme } from '../../constants/theme';
import {Router, Expense }from '@reach/router'

const Layout = ({ data, children }) => (

  
  <Grommet theme={cronoTheme}>
 
    <div>
      <GlobalStyle />
      <Head />

      <Header background="brand">
        <Heading hoverIndicator>{data.site.siteMetadata.siteTitle} </Heading>
        <EventSelect />
        <Menu
          items={[
            // {
            //   label: 'Evento',
            //   onClick: () => {
            //     navigate('/evento');
            //   },
            // },
            {
              label: 'Carreras',
              onClick: () => {
                navigate('/table');
              },
            },
            // {
            //   label: 'Formulario',
            //   onClick: () => {
            //     navigate('/form');
            //   },
            // },
          ]}
        />
      </Header>

      <Header title={data.site.siteMetadata.siteTitle} />
      {children}
    </div>
  </Grommet>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              siteTitle
            }
          }
        }
      `}
      render={data => <Layout data={data} {...props} />}
    />
  );
};

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
